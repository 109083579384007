var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"top"},[_c('div',{staticClass:"login"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.musicName),expression:"musicName"}],ref:"name",domProps:{"value":(_vm.musicName)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onSearch.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.musicName=$event.target.value}}}),_c('a',{attrs:{"href":"#","role":"button"},on:{"click":_vm.onAdd}},[_vm._v("新增")]),_c('a',{attrs:{"href":"#","role":"button"},on:{"click":_vm.onSearch}},[_vm._v("查找")])]),_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{ref:"platformCont",staticClass:"round_div_all"},_vm._l((_vm.platforms),function(item){return _c('div',{key:item.name},[_c('div',{staticClass:"round_div"},[_c('div',{staticClass:"r-s01"},[_c('a',{attrs:{"href":item.url,"target":"_blank"}},[(!item.logo)?_c('i',{staticClass:"bl-icon icon-platform",class:`bl-icon-website1`}):(item.logoType === 'FONT')?_c('i',{staticClass:"bl-icon icon-platform",class:`bl-icon-${item.logo}`}):_c('i',{staticClass:"bl-icon icon-platform",style:({
                                    width: '1em',
                                    height: '1em',
                                    borderRadius: '50%',
                                    backgroundImage: `url(${item.logo})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat'
                                })})])]),_c('div',{staticClass:"bl-cont"},[_c('div',{staticClass:"r-s03"},[_vm._v(_vm._s(item.theme))]),_c('div',{staticClass:"r-s02"},[_vm._v(_vm._s(item.name))])])])])}),0)]),_c('div',{staticClass:"content"},[_c('div',{ref:"squareCont",staticClass:"mian01"},_vm._l((_vm.sites),function(item){return _c('div',{key:item.name},[_c('div',{staticClass:"square_div"},[_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_c('div',{staticClass:"bl-cont squareCont"},[_c('div',{staticClass:"s-s01"},[(!item.logo)?_c('i',{staticClass:"bl-icon icon-link",class:`bl-icon-website1`}):(item.logoType === 'FONT')?_c('i',{staticClass:"bl-icon icon-link",class:`bl-icon-${item.logo}`}):_c('i',{staticClass:"bl-icon icon-link",style:({
                                        width: '1em',
                                        height: '1em',
                                        borderRadius: '50%',
                                        backgroundImage: `url(${item.logo})`,
                                        backgroundSize: 'contain',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat'
                                    })})]),_c('div',{staticClass:"bl-cont"},[_c('div',{staticClass:"s-s02"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"s-s03"},[_vm._v(_vm._s(item.theme))])])])])])])}),0)]),_c('div',{staticClass:"bottom"},[_vm._m(3),_c('div',{staticClass:"banquan"},[_c('audio',{ref:"audioPlayer",attrs:{"src":_vm.musics[0]}}),_c('div',[_c('i',{staticClass:"music-icon bl-icon bl-icon-Music-1",on:{"click":_vm.onModel}}),_c('i',{staticClass:"music-action-icon bl-icon bl-icon-skip-previous-fill",on:{"click":_vm.onPrev}}),(_vm.isPlaying)?_c('i',{staticClass:"music-action-icon bl-icon bl-icon-pause1",on:{"click":_vm.onPause}}):_c('i',{staticClass:"music-action-icon bl-icon bl-icon-play-arrow-fill",on:{"click":_vm.onStart}}),_c('i',{staticClass:"music-action-icon bl-icon bl-icon-skip-next-fill",on:{"click":_vm.onNext}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bl-cont"},[_c('div',{staticClass:"logo-cont"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/image/logo.svg"),"alt":"Blove logo"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bl-cont logo-title"},[_c('h1',[_vm._v("Blove For You")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bl-cont"},[_c('div',{staticClass:"top-text"},[_vm._v(" “Blove For You” 一种对生活细节的深情体会与感恩，它教会了我们在平凡的日常中发现非凡的美好。清晨的微笑、忙碌的奔波、不懈的坚持，正是我们对生活的热爱与执着。面对任何风浪，我们勇敢前行。正是这些点点滴滴，留下了我们走过的痕迹，铸造了人生的不平凡。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"down"},[_c('span',{staticClass:"f82"},[_vm._v("安全 | 服务条款 | 使用规范 | 客服中心 | 隐私保护指引")]),_c('br'),_c('span',{staticClass:"f75"},[_vm._v("Copyright © 1998-2024 All Rights Reserved.")])])
}]

export { render, staticRenderFns }